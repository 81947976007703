<template>
  <div>
    <PageBar Image="img/article_p_bn.jpg" :Breadcrumbs="Breadcrumbs"></PageBar>
    <div class="main">
      <div class="container" v-if="Document">
        <h1>
          {{ Document.Title
          }}<span class="subTitle" v-if="Document.SubTitle">{{
            Document.SubTitle
          }}</span>
        </h1>
        <div class="pageBox">
          <div v-html="Document.Content"></div>
          <div class="gallery">
            <section>
              <img
                v-for="i in Images"
                :key="i.ImageID"
                :src="i.ImageUrl | contentCDN"
                @click="showGallery($event)"
              />
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PageBar from "@/components/PageBar.vue";
import Menu from "@/plugins/menu.js";

export default {
  components: { PageBar },
  data() {
    return {
      Breadcrumbs: [],
      Document: null,
      Images: [],
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.setBase("articlePage document_preview");
      var result = JSON.parse(sessionStorage.getItem("preview"));
      if (!result) {
        alert("查無資料");
        window.location.href = "/";
        return;
      }
      console.log(result);
      this.Document = result.Document;
      this.Images = result.Images;
      var menu = Menu.MenuMap[this.Document.MenuID];

      var menus = Menu.getParents(menu).reverse();
      this.Breadcrumbs = [
        ...menus.map((x) => ({ Title: x.Title, Url: "javascript:void(0);" })),
        { Title: this.Document.Title, Url: "javascript:void(0);" },
      ];

      this.loadCustomJs();
    },
    showGallery($event) {
      var _this = $event.target;
      $(".lightbox").fadeIn(300);
      $(".lightbox").append(
        "<img src='" +
          $(_this).attr("src") +
          "' alt='" +
          $(_this).attr("alt") +
          "' />"
      );
      $(".filter").css("background-image", "url(" + $(_this).attr("src") + ")");
      /*$(".title").append("<h1>" + $(_this).attr("alt") + "</h1>");*/
      $("html").css("overflow", "hidden");
      if ($(_this).is(":last-child")) {
        $(".arrowr").css("display", "none");
        $(".arrowl").css("display", "block");
      } else if ($(_this).is(":first-child")) {
        $(".arrowr").css("display", "block");
        $(".arrowl").css("display", "none");
      } else {
        $(".arrowr").css("display", "block");
        $(".arrowl").css("display", "block");
      }
    },
  },
  watch: {
    $route(to, from) {
      this.load();
    },
  },
};
</script>